import { Languages } from "../enums";

export const DEFAULT_LANGUAGE = Languages.en;

export const AVAILABLE_LANGUAGES = [Languages.en, Languages.de, Languages.es, "bluebanana-brand"];

export const DARK = "#000";

export const LIGHT = "#FFF";

export const MULTI_PASS = "multiPass";

export const SHOPIFY_HOST = ".myshopify.com";

export const APPLE_WALLET_SCHEME = "wallet://";

export const GOOGLE_WALLET_SCHEME = "https://www.android.com/payapp";

export const GOOGLE_PASS = "https://pay.google.com/gp/v/object/";

export const WEB_WALLET_PASSES = "https://wallet.google.com/wallet/passes";

export const ENABLE_PUSHES_DOCS = "https://www.jericommerce.com/guides/im-not-receiving-notifications-on-my-wallet-what-can-i-do";
