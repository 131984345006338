import { Reward } from "../services/api";

const isValid = (reward: Reward): boolean => {
  const now = Date.now();

  let validFrom: number;
  let validTo: number;

  if (reward.validFrom) validFrom = new Date(reward.validFrom).getTime();
  else validFrom = new Date("1900-01-01").getTime();

  if (reward.validTo) validTo = new Date(reward.validTo).getTime();
  else validTo = new Date("2900-01-01").getTime();

  return now > validFrom && now < validTo && reward.redeemable;
};

const isExtrapoint = (reward: Reward): boolean => reward.usageType === Reward.UsageTypeEnum.Extrapoint;

const isMultiplier = (reward: Reward): boolean => reward.usageType === Reward.UsageTypeEnum.Multiplier;

const isReward = (reward: Reward): boolean => reward.usageType === Reward.UsageTypeEnum.Reward;

const isDiscount = (reward: Reward): boolean => reward.usageType === Reward.UsageTypeEnum.Discount;

const checkId = (a: Reward, b: Reward): number => {
  if (a.id < b.id) return -1;
  else if (a.id > b.id) return 1;
  else return 0;
};

const checkNeerValidity = (a: Reward, b: Reward): number => {
  const now = Date.now();

  let aValidFrom: number;
  let bValidFrom: number;

  if (a.validFrom) aValidFrom = new Date(a.validFrom).getTime();
  if (b.validFrom) bValidFrom = new Date(b.validFrom).getTime();

  if (aValidFrom && bValidFrom) {
    if (now - aValidFrom < now - bValidFrom) return -1;
    else if (now - aValidFrom > now - bValidFrom) return 1;
    else return checkId(a, b);
  }

  if (!aValidFrom && !bValidFrom) return checkId(a, b);
  if (!aValidFrom) return 1;
  else return -1;
};

const checkExtrapoint = (a: Reward, b: Reward): number => {
  if (isExtrapoint(a) && isExtrapoint(b)) return checkId(a, b);
  if (!isExtrapoint(a) && !isExtrapoint(b)) return checkId(a, b);
  if (isExtrapoint(a)) return -1;
  else return 1;
};

const checkMultiplier = (a: Reward, b: Reward): number => {
  if (isMultiplier(a) && isMultiplier(b)) return checkId(a, b);
  if (!isMultiplier(a) && !isMultiplier(b)) return checkExtrapoint(a, b);
  if (isMultiplier(a)) return -1;
  else return 1;
};

const checkReward = (a: Reward, b: Reward): number => {
  if (isReward(a) && isReward(b)) return checkId(a, b);
  if (!isReward(a) && !isReward(b)) return checkMultiplier(a, b);
  if (isReward(a)) return -1;
  else return 1;
};

const checkDiscount = (a: Reward, b: Reward): number => {
  if (isDiscount(a) && isDiscount(b)) return checkId(a, b);
  if (!isDiscount(a) && !isDiscount(b)) return checkReward(a, b);
  if (isDiscount(a)) return -1;
  else return 1;
};

const checkValidity = (a: Reward, b: Reward): number => {
  if (isValid(a) && isValid(b)) return checkDiscount(a, b);
  if (!isValid(a) && !isValid(b)) return checkNeerValidity(a, b);
  if (isValid(a)) return -1;
  else return 1;
};

export const sortRewards = (a: Reward, b: Reward): number => checkValidity(a, b);
