import { ActionReducerMap } from "@ngrx/store";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { UserState, initialState as user } from "./user/state";
import { userReducer } from "./user/reducer";
import { UserEffects } from "./user/effects";
import { AppState, initialState as app } from "./app/state";
import { appReducer } from "./app/reducer";
import { AppEffects } from "./app/effects";

export interface RootState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: RouterReducerState<any>;
  user: UserState;
  app: AppState;
}

export const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  user: userReducer,
  app: appReducer,
};

export const initialRootState: RootState = {
  router: { navigationId: 0, state: {} },
  user,
  app,
};

export const effects = [UserEffects, AppEffects];
