import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MailToOpenPipe } from "./mail-to-open.pipe";

@NgModule({
  declarations: [MailToOpenPipe],
  imports: [CommonModule],
  exports: [MailToOpenPipe],
})
export class MailToOpenPipeModule {}
