<ion-header>
    <ion-toolbar
        [style.background-color]="(program$ | async)?.configurations?.web?.backgroundColor"
        [style.background-image]="
            (program$ | async)?.configurations?.web?.bannerImage || 'assets/images/transparent.png' | getBackgroundImageStyle
        "
    >
        <div class="content">
            <img class="content__logo" [src]="(program$ | async)?.configurations?.web?.logo || 'assets/images/transparent.png'" />
        </div>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <div class="info__content">
        <ng-lottie [height]="lottieHeight" [options]="{ path: lottie, loop: false }"></ng-lottie>
        <p class="info__content__text --paragraph" [innerHTML]="text | translate"></p>
    </div>
</ion-content>

@if (mailToOpen) {
    <ion-footer class="info__footer">
        @if (mailToOpen | mailToOpen; as mailTo) {
            <ion-button class="program-button" target="_blank" [href]="mailTo" [innerHtml]="'OPEN_MAIL' | translate"></ion-button>
        }
        <ion-button
            class="info__footer__cancel"
            color="dark"
            fill="outline"
            [innerHtml]="'CLOSE' | translate"
            (click)="close()"
        ></ion-button>
    </ion-footer>
} @else if (button) {
    <ion-footer class="info__footer">
        <ion-button class="program-button" [innerHTML]="button | translate" (click)="close()"></ion-button>
    </ion-footer>
}
