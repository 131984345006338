import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "mailToOpen" })
export class MailToOpenPipe implements PipeTransform {
  public transform(email: string): string {
    if (email) {
      const emailParts = email.split("@");
      if (emailParts?.length > 1) {
        const domain = emailParts[1];
        switch (domain.toLowerCase()) {
          case "gmail.com":
            return "https://mail.google.com/";
          case "outlook.com":
          case "hotmail.com":
          case "live.com":
            return "https://outlook.live.com/";
          case "yahoo.com":
            return "https://mail.yahoo.com/";
          case "icloud.com":
            return "https://www.icloud.com/mail";
          default:
            return null;
        }
      }
    }
    return null;
  }
}
