import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";

@Component({
  selector: "app-context-toast",
  templateUrl: "./context-toast.component.html",
  styleUrls: ["./context-toast.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextToastComponent implements AfterViewInit {
  public animation: "out" | "in" = "in";
  @Input() public title: string;
  @Input() public message: string;
  @Input() public type: "info" | "error" = "info";
  @Input() public duration: number;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.animation = "out";
      this.changeDetector.markForCheck();
    }, this.duration - 300);
  }
}
