import { RootState } from "..";
import { AppState } from "./state";
import { createSelector, DefaultProjectorFn, MemoizedSelector } from "@ngrx/store";

const selectFeature = (state: RootState): AppState => state.app;

export const selectLoading = createSelector(selectFeature, state => state.loading);

export const selectLanguage = createSelector(selectFeature, state => state.language);

export const selectProgram = createSelector(selectFeature, state => state.program);

export const selectProgramId = createSelector(selectFeature, state => state.program?.id);

export const selectSession = createSelector(selectFeature, state => state.session);

export const selectError = createSelector(selectFeature, state => state.error);

export const selectCoupon = createSelector(selectFeature, state => state.coupon);

export const selectProgramIntegrations = createSelector(selectFeature, state => state.programIntegrations);

export const selectFeatures = createSelector(selectFeature, state => state.features);

export const selectIsFeatureEnabledByName = (featureName: string): MemoizedSelector<RootState, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectFeature, state =>
    Object.values(state.programIntegrations)?.some(programIntegration => {
      const features = programIntegration?.enabledFeatures?.map(id => state.features.find(feature => feature.id === id)).filter(Boolean);
      return features?.some(feature => feature?.name === featureName);
    }),
  );
