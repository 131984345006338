import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  Reward,
  Customer,
  CustomerData,
  GooglePass,
  ProgramControllerActivateCustomerRewardRequestParams,
  ProgramControllerCheckCustomerExistsRequestParams,
  ProgramControllerDeactivateCustomerRewardRequestParams,
  ProgramControllerGetApplePassRequestParams,
  ProgramControllerGetCustomerCouponsRequestParams,
  ProgramControllerGetCustomerDataRequestParams,
  ProgramControllerGetGooglePassRequestParams,
  ProgramControllerRegisterCustomerRequestParams,
  ProgramControllerSendPassToCustomerRequestParams,
  ProgramControllerSendValidationEmailRequestParams,
  ProgramControllerStoreCustomerClickRequestParams,
  ProgramControllerUpdateCustomerPreferencesRequestParams,
  ProgramControllerValidateCustomerRequestParams,
  ProgramControllerClaimCustomerRewardRequestParams,
  ClaimedReward,
  ProgramControllerGetCustomerMultiPassLinkRequestParams,
  MultiPassLink,
  ProgramControllerGetCouponUniqueCodeApplePassRequestParams,
  ProgramControllerGetCouponUniqueCodeGooglePassRequestParams,
  MinimalPassDto,
  ProgramControllerGetAnonymousCouponApplePassRequestParams,
  ProgramControllerGetAnonymousCouponGooglePassRequestParams,
} from "src/app/services/api";

export const setAccessToken = createAction("@user/set-access-token", props<{ accessToken: string }>());

export const logout = createAction("@user/logout", props<{ callback: () => void }>());

export const setError = createAction("@user/set-error", props<{ error: HttpErrorResponse }>());

export const resetError = createAction("@user/reset-error");

export const checkCustomerExists = createAction("@user/check-customer-exists", props<ProgramControllerCheckCustomerExistsRequestParams>());
export const checkCustomerExistsSuccess = createAction("@user/check-customer-exists-success");
export const checkCustomerExistsFailure = createAction(
  "@user/check-customer-exists-failure",
  props<{ error: HttpErrorResponse; email: string }>(),
);

export const sendPassToCustomer = createAction("@user/send-pass-to-customer", props<ProgramControllerSendPassToCustomerRequestParams>());
export const sendPassToCustomerSuccess = createAction("@user/send-pass-to-customer-success", props<{ email: string }>());
export const sendPassToCustomerFailure = createAction("@user/send-pass-to-customer-failure", props<{ error: HttpErrorResponse }>());

export const validateCustomer = createAction("@user/validate-customer", props<ProgramControllerValidateCustomerRequestParams>());
export const validateCustomerSuccess = createAction("@user/validate-customer-success");
export const validateCustomerFailure = createAction("@user/validate-customer-failure", props<{ error: HttpErrorResponse }>());

export const downloadPass = createAction(
  "@user/download-pass",
  props<{ params: ProgramControllerGetApplePassRequestParams; platform: string }>(),
);

export const downloadCouponPass = createAction(
  "@user/download-coupon-pass",
  props<{ params: ProgramControllerGetCouponUniqueCodeApplePassRequestParams; platform: string }>(),
);

export const downloadAnonymousCouponPass = createAction(
  "@user/download-anonymous-coupon-pass",
  props<{ params: ProgramControllerGetAnonymousCouponApplePassRequestParams; platform: string }>(),
);

export const register = createAction("@user/register", props<ProgramControllerRegisterCustomerRequestParams>());
export const registerFailure = createAction(
  "@user/register-failure",
  props<{
    error: HttpErrorResponse;
    params: ProgramControllerRegisterCustomerRequestParams;
  }>(),
);

export const getApplePass = createAction("@user/get-apple-pass", props<ProgramControllerGetApplePassRequestParams>());
export const getAppleCouponPass = createAction(
  "@user/get-apple-coupon-pass",
  props<ProgramControllerGetCouponUniqueCodeApplePassRequestParams>(),
);
export const getAppleAnonymousCouponPass = createAction(
  "@user/get-apple-anonymous-coupon-pass",
  props<ProgramControllerGetAnonymousCouponApplePassRequestParams>(),
);
export const getApplePassSuccess = createAction(
  "@user/get-apple-pass-success",
  props<{
    response: Blob;
    kind: MinimalPassDto.KindEnum;
    couponSlug?: string;
    couponCode?: string;
    campaignSlug?: string;
    params: ProgramControllerGetApplePassRequestParams;
  }>(),
);
export const getApplePassFailure = createAction("@user/get-apple-pass-failure", props<{ error: HttpErrorResponse }>());

export const getGooglePass = createAction("@user/get-google-pass", props<ProgramControllerGetGooglePassRequestParams>());
export const getGoogleCouponPass = createAction(
  "@user/get-google-coupon-pass",
  props<ProgramControllerGetCouponUniqueCodeGooglePassRequestParams>(),
);
export const getGoogleAnonymousCouponPass = createAction(
  "@user/get-google-anonymous-coupon-pass",
  props<ProgramControllerGetAnonymousCouponGooglePassRequestParams>(),
);
export const getGooglePassSuccess = createAction(
  "@user/get-google-pass-success",
  props<{ response: GooglePass & { kind: MinimalPassDto.KindEnum; couponSlug?: string; couponCode?: string; campaignSlug?: string } }>(),
);
export const getGooglePassFailure = createAction("@user/get-google-pass-failure", props<{ error: HttpErrorResponse }>());

export const getCustomerData = createAction("@user/get-customer-data", props<ProgramControllerGetCustomerDataRequestParams>());
export const getCustomerDataSuccess = createAction("@user/get-customer-data-success", props<{ customerData: CustomerData }>());
export const getCustomerDataFailure = createAction("@user/get-customer-data-failure");

export const getCustomerRewards = createAction("@user/get-customer-rewards", props<ProgramControllerGetCustomerCouponsRequestParams>());
export const getCustomerRewardsSuccess = createAction("@user/get-customer-rewards-success", props<{ rewards: Reward[] }>());
export const getCustomerRewardsFailure = createAction("@user/get-customer-rewards-failure");

export const activateCustomerReward = createAction(
  "@user/activate-customer-reward",
  props<ProgramControllerActivateCustomerRewardRequestParams>(),
);
export const activateCustomerRewardSuccess = createAction("@user/activate-customer-reward-success", props<{ rewardId: string }>());
export const activateCustomerRewardFailure = createAction("@user/activate-customer-reward-failure");

export const deactivateCustomerReward = createAction(
  "@user/deactivate-customer-reward",
  props<ProgramControllerDeactivateCustomerRewardRequestParams>(),
);
export const deactivateCustomerRewardSuccess = createAction("@user/deactivate-customer-reward-success", props<{ rewardId: string }>());
export const deactivateCustomerRewardFailure = createAction("@user/deactivate-customer-reward-failure");

export const sendValidationEmail = createAction("@user/send-validation-email", props<ProgramControllerSendValidationEmailRequestParams>());
export const sendValidationEmailSuccess = createAction("@user/send-validation-email-success", props<{ email: string }>());
export const sendValidationEmailFailure = createAction("@user/send-validation-email-failure");

export const updateCustomerPreferences = createAction(
  "@user/update-customer-preferences",
  props<ProgramControllerUpdateCustomerPreferencesRequestParams>(),
);
export const updateCustomerPreferencesSuccess = createAction("@user/update-customer-preferences-success", props<{ customer: Customer }>());
export const updateCustomerPreferencesFailure = createAction("@user/update-customer-preferences-failure");

export const storeCustomerClickAndRedirect = createAction(
  "@user/store-customer-click-and-redirect",
  props<ProgramControllerStoreCustomerClickRequestParams>(),
);
export const storeCustomerClickAndRedirectSuccess = createAction(
  "@user/store-customer-click-and-redirect-success",
  props<{ redirectTo: string }>(),
);
export const storeCustomerClickAndRedirectFailure = createAction(
  "@user/store-customer-click-and-redirect-failure",
  props<{ redirectTo: string; error: HttpErrorResponse }>(),
);

export const claimCustomerReward = createAction(
  "@user/claim-customer-reward",
  props<{ params: ProgramControllerClaimCustomerRewardRequestParams; balance: number; balanceName: string }>(),
);
export const claimCustomerRewardSuccess = createAction("@user/claim-customer-reward-success", props<{ claimedReward: ClaimedReward }>());
export const claimCustomerRewardFailure = createAction("@user/claim-customer-reward-failure", props<{ error: HttpErrorResponse }>());

export const openShopLink = createAction("@user/open-shop-link", props<ProgramControllerGetCustomerMultiPassLinkRequestParams>());
export const openShopLinkSuccess = createAction("@user/open-shop-link-success", props<{ links: MultiPassLink[] }>());
export const openShopLinkFailure = createAction("@user/open-shop-link-failure");

export const emptyClaimedRewards = createAction("@user/empty-claimed-rewards");

export const setReferral = createAction("@user/set-referral", props<{ referral: string }>());
