import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, catchError, map, mergeMap, of, switchMap, tap, withLatestFrom } from "rxjs";
import { Action, Store } from "@ngrx/store";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import { RootState } from "..";
import { AppRoutes, Languages } from "src/app/enums";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import { DOCUMENT, registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AdminService, CouponService, ProgramService } from "src/app/services/api";
import { HttpErrorResponse } from "@angular/common/http";
import { NavController } from "@ionic/angular";
import { Title } from "@angular/platform-browser";

@Injectable()
export class AppEffects {
  public changeLanguage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.changeLanguage),
      withLatestFrom(this.store.select(FromApp.selectLanguage)),
      mergeMap(([action, language]) => {
        const shouldReload = language !== action.language;

        switch (action.language) {
          case Languages.en:
            registerLocaleData(localeEn);
            break;
          case Languages.de:
            registerLocaleData(localeDe);
            break;
          case Languages.es:
            registerLocaleData(localeEs);
            break;
        }

        this.translateService.use(action.language);

        if (shouldReload) this.document.location.reload();

        return [AppActions.changeLanguageSuccess({ language: action.language })];
      }),
    ),
  );

  public getProgram$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getProgram),
      switchMap(({ slug, programId }) =>
        this.programService.programControllerGetProgram({ programId: slug || programId }).pipe(
          map(program => AppActions.getProgramSuccess({ program })),
          catchError((error: HttpErrorResponse) => of(AppActions.getProgramFailure({ error: error.error.message }))),
        ),
      ),
    ),
  );

  public getProgramSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.getProgramSuccess),
        tap(({ program }) => {
          this.title.setTitle(program.name);
          const dark = getComputedStyle(this.document.documentElement).getPropertyValue("--ion-color-dark");
          const darkContrast = getComputedStyle(this.document.documentElement).getPropertyValue("--ion-color-dark-contrast");

          this.document.documentElement.style.setProperty(
            "--ion-color-program-background",
            program.configurations.web.backgroundColor || dark,
          );
          this.document.documentElement.style.setProperty(
            "--ion-color-program-foreground",
            program.configurations.web.foregroundColor || darkContrast,
          );

          this.store.dispatch(AppActions.getProgramIntegrations({ programId: program.id }));
          this.store.dispatch(AppActions.getFeatures());
        }),
      ),
    { dispatch: false },
  );

  public getProgramFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.getProgramFailure),
        tap(_error => this.navController.navigateRoot([AppRoutes.notFound])),
      ),
    { dispatch: false },
  );

  public getProgramIntegrations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getProgramIntegrations),
      switchMap(params =>
        this.programService.programControllerGetProgramIntegrations(params).pipe(
          map(programIntegrations => AppActions.getProgramIntegrationsSuccess({ programIntegrations })),
          catchError(() => of(AppActions.getProgramIntegrationsFailure())),
        ),
      ),
    ),
  );

  public getFeatures$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getFeatures),
      switchMap(() =>
        this.adminService.adminControllerGetFeatures().pipe(
          map(features => AppActions.getFeaturesSuccess({ features })),
          catchError(() => of(AppActions.getFeaturesFailure())),
        ),
      ),
    ),
  );

  public getCoupon$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.getCoupon),
      mergeMap(({ programId, couponId }) =>
        this.couponService.couponControllerGetCouponById({ programId, couponId }).pipe(
          map(coupon => AppActions.getCouponSuccess({ coupon })),
          catchError((error: HttpErrorResponse) => of(AppActions.getCouponFailure({ error: error?.error?.message }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly title: Title,
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly adminService: AdminService,
    private readonly programService: ProgramService,
    private readonly couponService: CouponService,
    private readonly translateService: TranslateService,
    private readonly navController: NavController,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
