import { Languages } from "src/app/enums";
import { CouponDto, Feature, Program, ProgramIntegration } from "src/app/services/api";

export interface AppState {
  language: Languages;
  program: Program;
  features: Feature[];
  programIntegrations: ProgramIntegration[];
  loading: boolean;
  session: { id: string; dateTime: Date };
  coupon: CouponDto | null;
  error: string | null;
}

export const initialState: AppState = {
  language: Languages.en,
  program: null,
  features: [],
  programIntegrations: [],
  loading: false,
  session: { id: null, dateTime: null },
  coupon: null,
  error: null,
};

export const persistedKeys: (keyof AppState)[] = ["language", "session"];
