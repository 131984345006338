/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LottieModule } from "ngx-lottie";
import { InfoComponent } from "./info.component";
import { TranslateModule } from "@ngx-translate/core";
import { MailToOpenPipeModule } from "src/app/pipes/mail-to-open/mail-to-open.module";
import { GetBackgroundImageStylePipeModule } from "src/app/pipes/get-background-image-style/get-background-image-style.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    MailToOpenPipeModule,
    LottieModule.forRoot({ player: () => import(/* webpackChunkName: 'lottie-web' */ "lottie-web/build/player/lottie_svg") }),
    GetBackgroundImageStylePipeModule,
  ],
  declarations: [InfoComponent],
  exports: [InfoComponent],
})
export class InfoComponentModule {}
