import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Program } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as FromApp from "src/app/store/app/selectors";

export interface InfoComponentParams {
  lottie: string;
  lottieHeight: string;
  text: string;
  button?: string;
  mailToOpen?: string;
}

@Component({
  selector: "app-info-modal",
  templateUrl: "info.component.html",
  styleUrls: ["info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  @Input() public lottie: string;
  @Input() public lottieHeight: string;
  @Input() public text: string;
  @Input() public button: string;
  @Input() public mailToOpen: string;

  public readonly program$: Observable<Program> = this.store.select(FromApp.selectProgram);

  constructor(
    private readonly store: Store<RootState>,
    private readonly modalController: ModalController,
  ) {}

  public close(): void {
    this.modalController.dismiss();
  }
}
