/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, catchError, combineLatest, first, switchMap, throwError } from "rxjs";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";
import * as FromRouter from "src/app/store/router/selectors";
import * as UserActions from "src/app/store/user/actions";
import { NavController } from "@ionic/angular";
import { AppRoutes } from "src/app/enums";
import { jwtDecode } from "jwt-decode";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private readonly url$: Observable<string> = this.store.select(FromRouter.selectUrl);
  private readonly programSlug$: Observable<string> = this.store.select(FromRouter.selectProgramSlug);

  private readonly accessToken$: Observable<string> = this.store.select(FromUser.selectAccessToken);

  constructor(
    private readonly store: Store<RootState>,
    private readonly navController: NavController,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.accessToken$, this.programSlug$, this.url$]).pipe(
      first(),
      switchMap(([token, slug, url]) => {
        if (token) req = req.clone({ setHeaders: { authorization: `Bearer ${token}` } });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= HttpStatusCode.InternalServerError) console.error(error);

            if (error.status === HttpStatusCode.Unauthorized) {
              this.store.dispatch(
                UserActions.logout({
                  callback: () => {
                    localStorage.clear();

                    if (!url?.includes(AppRoutes.unauthorized)) {
                      if (error?.error?.message?.includes("DOWNLOAD_TOKEN_EXPIRED")) {
                        let email: string;

                        try {
                          const payload = jwtDecode(token);
                          email = payload["email"];
                        } catch (e) {}

                        this.navController.navigateRoot([slug, AppRoutes.unauthorizedDownload], { queryParams: { email } });
                      } else {
                        const [_, identifier, serial_number] = error?.error?.message?.split(".");
                        this.navController.navigateRoot([slug, AppRoutes.unauthorized], { queryParams: { identifier, serial_number } });
                      }
                    }
                  },
                }),
              );
            }

            return throwError(() => error);
          }),
        );
      }),
      catchError(error => throwError(() => error)),
    );
  }
}
