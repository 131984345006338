import { Injectable, ApplicationRef, Inject, createComponent, ChangeDetectorRef } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ContextToastComponent } from "../../components/context-toast/context-toast.component";

@Injectable({
  providedIn: "root",
  deps: [ChangeDetectorRef],
})
export class ToastService {
  constructor(
    private readonly appRef: ApplicationRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public show(
    {
      title,
      message,
      type = "info",
      duration = 3000,
    }: {
      title: string;
      message: string;
      type?: "info" | "error";
      duration?: number;
    },
    uniqueCSSselector?: string,
  ): void {
    const hostElement = this.document.querySelector(uniqueCSSselector || "#toastContainer");

    const containerElement = this.document.createElement("div");
    hostElement.append(containerElement);

    const toastComponentRef = createComponent(ContextToastComponent, {
      hostElement: containerElement,
      environmentInjector: this.appRef.injector,
    });

    toastComponentRef.instance.title = title;
    toastComponentRef.instance.message = message;
    toastComponentRef.instance.type = type;
    toastComponentRef.instance.duration = duration;

    this.appRef.attachView(toastComponentRef.hostView);

    setTimeout(() => {
      this.appRef.detachView(toastComponentRef.hostView);
      toastComponentRef.destroy();
    }, duration);
  }
}
