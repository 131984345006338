/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, first, switchMap } from "rxjs";
import { Program } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { ActivatedRoute } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AppInterceptor implements HttpInterceptor {
  private readonly userId$: Observable<string> = this.store.select(FromUser.selectUserId);
  private readonly program$: Observable<Program> = this.store.select(FromApp.selectProgram);

  constructor(
    private readonly store: Store<RootState>,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.userId$, this.program$]).pipe(
      first(),
      switchMap(([userId, program]) => {
        req = req.clone({ setHeaders: { "X-APP": "customer" } });
        req = req.clone({ setHeaders: { "X-PATHNAME": this.getRouteConfigPath() } });
        req = req.clone({ setHeaders: { "X-APP-TIMEZONE": Intl.DateTimeFormat().resolvedOptions().timeZone } });

        if (userId) req = req.clone({ setHeaders: { "X-USER-ID": userId } });
        if (program?.id) req = req.clone({ setHeaders: { "X-PROGRAM-ID": program?.id } });
        if (program?.slug) req = req.clone({ setHeaders: { "X-PROGRAM-SLUG": program?.slug } });

        return next.handle(req);
      }),
    );
  }

  private getRouteConfigPath(): string {
    let url = "";
    let currentRoute = this.activatedRoute.root;

    while (currentRoute.children.length > 0) {
      const child = currentRoute.children[0];
      if (child?.routeConfig?.path) url += "/" + child.routeConfig.path;
      currentRoute = child;
    }

    return url;
  }
}
