/* eslint-disable @typescript-eslint/no-explicit-any */
import * as FromApp from "src/app/store/app/selectors";
import * as AppActions from "src/app/store/app/actions";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, first, switchMap } from "rxjs";
import { RootState } from "src/app/store";
import { v4 as randomUUID } from "uuid";

@Injectable({ providedIn: "root" })
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromApp.selectSession).pipe(
      first(),
      switchMap(session => {
        let sessionId = session.id;

        if (!sessionId || this.moreThan24HoursAgo(session.dateTime)) {
          sessionId = randomUUID();
          this.store.dispatch(AppActions.setSessionId({ sessionId }));
        }

        if (session) req = req.clone({ setHeaders: { "X-SESSION-ID": sessionId } });

        return next.handle(req);
      }),
    );
  }

  private moreThan24HoursAgo(date: Date): boolean {
    return Date.now() - date.getTime() >= 86_400_000;
  }
}
