import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Pipe({ name: "getBackgroundImageStyle" })
export class GetBackgroundImageStylePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  public transform(value: string): SafeStyle {
    const style = `linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%), url('${value}')`;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
