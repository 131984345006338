import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as AppActions from "src/app/store/app/actions";
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from "./constants";
import { Languages } from "./enums";
import { combineLatest, filter, firstValueFrom, map, Observable } from "rxjs";
import * as FromApp from "src/app/store/app/selectors";
import { Feature, Program, ProgramIntegration } from "./services/api";

export const initializeApp =
  (store: Store<RootState>): Function =>
    async () => {
      try {
        const program$: Observable<Program> = store.select(FromApp.selectProgram);
        const programIntegrations$: Observable<ProgramIntegration[]> = store.select(FromApp.selectProgramIntegrations);
        const features$: Observable<Feature[]> = store.select(FromApp.selectFeatures);

        const slug = window.location.pathname.split("/").filter(Boolean).shift();
        const userLang = navigator.language.split("-")[0] as Languages;

        let language: Languages;

        if (AVAILABLE_LANGUAGES.includes(slug)) language = slug as Languages;
        else if (AVAILABLE_LANGUAGES.includes(userLang)) language = userLang;
        else language = DEFAULT_LANGUAGE;

        store.dispatch(AppActions.getProgram({ slug: slug }));
        store.dispatch(AppActions.changeLanguage({ language }));

        await firstValueFrom(
          combineLatest([program$, programIntegrations$, features$]).pipe(
            filter(([program, programIntegrations, features]) => program?.slug === slug &&
              !!programIntegrations?.length &&
              !!features?.length),
            map(() => true),
          ),
        );
      } catch (error) {
        console.error(error);
      } finally {
        document.querySelector(".--root-loading").classList.remove("--root-loading");
      }
    };
