/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, first, switchMap } from "rxjs";
import { RootState } from "src/app/store";
import * as FromApp from "src/app/store/app/selectors";

@Injectable({ providedIn: "root" })
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromApp.selectLanguage).pipe(
      first(),
      switchMap(language => {
        req = req.clone({ setHeaders: { "X-APP-LANGUAGE": language } });
        return next.handle(req);
      }),
    );
  }
}
